<template>
  <div>
    <!-- Configuration / Insertion ou update d'une entrée -->
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title>
        <h2 class="text-xl mb-6">Ajout de set-up</h2>
      </v-card-title>
      <v-row>
        <v-select
          class="ml-6 mr-6"
          v-model="typeSelected"
          :items="typeSelection"
          item-text="name"
          item-value="id"
          label="Type de rédacteur"
          outlined
        ></v-select>
      </v-row>
      <v-row>
        <RedacFilter class="ml-6 mr-6" store="redac"></RedacFilter>
      </v-row>
      <div v-if="typeSelected == 1">
        <v-row>
          <v-select
            class="ml-6 mr-6"
            v-model="periodSelected"
            :items="periods"
            item-text="name"
            item-value="id"
            multiple
            label="Période"
            outlined
          ></v-select>
        </v-row>
        <v-row>
          <v-text-field
            class="ml-6 mr-6"
            v-model="price"
            label="Prix"
            outlined
          ></v-text-field>
        </v-row>
      </div>
      <v-row>
        <v-btn color="primary" class="ml-6 mr-6 mb-6" @click="emitSave"
          >Enregistrer</v-btn
        >
        <!-- <v-btn outlined color="secondary" class="ml-6 mr-6 mb-6"
          >Annuler
        </v-btn> -->
      </v-row>
    </v-card>
  </div>
</template>
<script>
import RedacFilter from "@/components/common/filters/RedacFilter.vue";
export default {
  props: ["sites", "periods"],
  components: {
    RedacFilter,
  },
  data() {
    return {
      typeSelected: 1,
      periodSelected: null,
      price: 0,
      typeSelection: [
        {
          id: 0,
          name: "Rédacteur Interne",
        },
        { id: 1, name: "Rédacteur Externe" },
      ],
    };
  },
  methods: {
    emitSave() {
      // Emit an event with necessary data
      this.$emit("save", {
        redacSelected: this.redacSelected,
        periodSelected: this.periodSelected,
        typeSelected: this.typeSelected,
        price: this.price,
      });
    },
  },
  computed: {
    redacSelected() {
      return this.$store.getters["redac/getRedacs"];
    },
  },
  watch: {
    typeSelected(newVal) {
      if (newVal !== 1) {
        this.periodSelected = [];
        this.price = "";
      }
    },
  },
};
</script>
